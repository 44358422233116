<template>
  <div class="wrap">
    <div class="skill-nav">
      <el-button type="warning" class="button" @click="handleMyList">
        我的雅思课程
      </el-button>
      <div class="nav-menu">
        <!--      <div @select="handleSelect" :default-active="`${menuId}`">-->
        <div class="group-menu" v-for="group of list" :key="group.id">
          <div class="group-title">
            <p>{{ group.name }}</p>
            <img v-if="group.img_logo" :src="group.img_logo" alt="" />
          </div>

          <ul>
            <li
              v-for="item of group.sub_list"
              :key="item.id"
              :index="`${item.id}`"
              :class="{ actived: menuId == item.id }"
              @click="handleSelect(item.id)"
            >
              <div class="icon">
                <template v-if="/http/.test(item.img_logo)">
                  <img :src="item.img_logo" alt="" />
                </template>
                <template v-else>
                  <i class="iconfont" :class="item.img_logo"></i>
                </template>
              </div>

              <span class="menu-title">{{ item.name }}</span>

              <i class="iconfont icon-class-right arrow"></i>
            </li>
          </ul>
        </div>

        <!-- <div class="all-class" @click="handleAllList">查看所有课程</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { apiCourseCate } from '@/api/IELTScourse'

export default {
  props: {
    cateSub: {
      type: Number,
      default: 0
    },
    onChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      list: [],
      cateList: []
    }
  },
  computed: {
    menuData() {
      return (
        this.cateList.find(
          (m) => Number(this.$route.query.cateSub || this.cateSub) === m.id
        ) || {}
      )
    },
    menuId() {
      return this.menuData.id
    }
  },
  watch: {
    menuData() {
      this.onChange(this.menuData)
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      const { data } = await apiCourseCate()
      this.cateList = data.reduce((list, item) => {
        return list.concat(item.sub_list)
      }, [])
      this.list = data
    },

    handleSelect(cateId) {
      if (this.$route.query.cateSub === cateId) {
        return false
      }
      const cateData = this.cateList.find((m) => m.id === Number(cateId))
      this.$router.replace({
        name: 'IELTScourse',
        query: {
          ...this.$route.query,
          cateMain: cateData.pid,
          cateSub: cateData.id
        }
      })
    },

    handleMyList() {
      this.$router.push({
        name: 'IELTScourseMyList'
      })
    },
    handleAllList() {
      this.$router.push({
        name: 'IELTScourseAllList'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  min-width: 236px;
  .skill-nav {
    z-index: 99;
    position: fixed;
    width: 236px;
    padding-left: 10px;
    background-color: #f9f9f9;
    padding-right: 20px;
    padding-top: 25px;
    height: calc(100vh - 66px);
    overflow-y: auto;
    padding-bottom: 80px;
    box-sizing: border-box;
    /* 设置滚动条的样式 */
    &::-webkit-scrollbar {
      width: 6px;
    }
    /* 滚动槽 */
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #dcdcdc;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background-color: #dcdcdc;
    }
    .button {
      background-color: #ffc06a;
      border-color: #ffc06a;
      width: 100%;
    }
    .nav-menu {
      .group-menu {
        margin-top: 16px;
        .group-title {
          color: #1f1f40;
          font-size: 20px;
          font-weight: bold;
          padding: 6px 0;
          border-bottom: 1px solid #dcdcdc;
          display: flex;
          align-items: center;
          > img {
            margin-left: 10px;
            border-radius: 3px;
            height: 18px;
          }
        }
        > ul {
          margin-top: 5px;
          > li {
            margin-top: 16px;
            font-size: 16px;
            color: #676767;
            display: flex;
            align-items: center;
            cursor: pointer;
            &.actived {
              color: #3b86ff;
              .arrow {
                opacity: 1;
              }
            }
            &:hover {
              color: #3b86ff;
            }
            .icon {
              width: 42px;
              > img {
                width: 28px;
                transform: translateX(-7px);
              }
              > i {
                font-size: 16px;
              }
            }
            .menu-title {
              flex: 1;
            }
            .arrow {
              font-size: 12px;
              color: #676767;
              opacity: 0;
            }
          }
        }
      }
      .all-class {
        margin-top: 36px;
        border-top: 1px solid #dcdcdc;
        padding-top: 24px;
        font-size: 16px;
        color: #676767;
        cursor: pointer;
      }
    }
  }
}
</style>
